<template lang="pug">
div
  v-text-field(
    label="Label"
    v-model="formData.label"
    data-test="custom-template-label"
    :readonly="formData.readonly"
    :disabled="formData.readonly"
    :error="formErrors.hasError('label')"
    :error-messages="formErrors.fields.label"
  )
  v-text-field(
    label="Subject"
    v-model="formData.subject"
    :disabled="formData.readonly"
    :readonly="formData.readonly"
    :error="formErrors.hasError('subject')"
    :error-messages="formErrors.fields.subject"
  )
  v-textarea(
    outlined
    label="Content"
    :rows="12"
    placeholder="Type your text here..."
    :readonly="formData.readonly"
    v-model="formData.content"
    :disabled="formData.readonly"
    :error="formErrors.hasError('content')"
    :error-messages="formErrors.fields.content"
  )

</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    formErrors: Object
  },

  data:() => ({
    formData: {
      label: null,
      subject: null,
      content: null,
      readonly: false
    }
  }),

  mounted() {
    this.setData()
  },

  methods: {
    setData() {
      if(!this.item) return
      this.formData.label = this.item.label
      this.formData.subject = this.item.subject
      this.formData.content = this.item.content
      this.formData.readonly = this.item.readonly
    },

    getData() {
      return this.formData
    }
  },

  components: {

  },
}
</script>

<style lang="scss" scoped>

</style>